import plugin_vue3_xKuw1km5sV from "/tmp/build_96f1cc98/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_YTGlmQWWT5 from "/tmp/build_96f1cc98/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_96f1cc98/apps/web/.nuxt/components.plugin.mjs";
import unhead_neSs9z3UJp from "/tmp/build_96f1cc98/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/tmp/build_96f1cc98/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_8FoqvPFTot from "/tmp/build_96f1cc98/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_j3xPeZV1re from "/tmp/build_96f1cc98/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/tmp/build_96f1cc98/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apexcharts_client_8BPXCV7eXX from "/tmp/build_96f1cc98/apps/web/src/plugins/apexcharts.client.ts";
import datepicker_MYSkxGBq4N from "/tmp/build_96f1cc98/apps/web/src/plugins/datepicker.ts";
export default [
  plugin_vue3_xKuw1km5sV,
  revive_payload_client_YTGlmQWWT5,
  components_plugin_KR1HBZs4kY,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  prefetch_client_8FoqvPFTot,
  plugin_j3xPeZV1re,
  chunk_reload_client_SeG0EjL5Ec,
  apexcharts_client_8BPXCV7eXX,
  datepicker_MYSkxGBq4N
]