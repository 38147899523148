import { default as adminEoE7y01nZfMeta } from "/tmp/build_96f1cc98/apps/web/src/pages/dashboard/admin.vue?macro=true";
import { default as hostingd9tBg38rtLMeta } from "/tmp/build_96f1cc98/apps/web/src/pages/dashboard/hosting.vue?macro=true";
import { default as indexhnboBZX46rMeta } from "/tmp/build_96f1cc98/apps/web/src/pages/dashboard/index.vue?macro=true";
import { default as userENuAlCU8alMeta } from "/tmp/build_96f1cc98/apps/web/src/pages/dashboard/user.vue?macro=true";
import { default as dashboard2VOvHX9DjZMeta } from "/tmp/build_96f1cc98/apps/web/src/pages/dashboard.vue?macro=true";
import { default as indexcSvZYyk7ygMeta } from "/tmp/build_96f1cc98/apps/web/src/pages/index.vue?macro=true";
export default [
  {
    path: dashboard2VOvHX9DjZMeta?.path ?? "/dashboard",
    children: [
  {
    name: adminEoE7y01nZfMeta?.name ?? "dashboard-admin",
    path: adminEoE7y01nZfMeta?.path ?? "admin",
    meta: adminEoE7y01nZfMeta || {},
    alias: adminEoE7y01nZfMeta?.alias || [],
    redirect: adminEoE7y01nZfMeta?.redirect || undefined,
    component: () => import("/tmp/build_96f1cc98/apps/web/src/pages/dashboard/admin.vue").then(m => m.default || m)
  },
  {
    name: hostingd9tBg38rtLMeta?.name ?? "dashboard-hosting",
    path: hostingd9tBg38rtLMeta?.path ?? "hosting",
    meta: hostingd9tBg38rtLMeta || {},
    alias: hostingd9tBg38rtLMeta?.alias || [],
    redirect: hostingd9tBg38rtLMeta?.redirect || undefined,
    component: () => import("/tmp/build_96f1cc98/apps/web/src/pages/dashboard/hosting.vue").then(m => m.default || m)
  },
  {
    name: indexhnboBZX46rMeta?.name ?? "dashboard",
    path: indexhnboBZX46rMeta?.path ?? "",
    meta: indexhnboBZX46rMeta || {},
    alias: indexhnboBZX46rMeta?.alias || [],
    redirect: indexhnboBZX46rMeta?.redirect || undefined,
    component: () => import("/tmp/build_96f1cc98/apps/web/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: userENuAlCU8alMeta?.name ?? "dashboard-user",
    path: userENuAlCU8alMeta?.path ?? "user",
    meta: userENuAlCU8alMeta || {},
    alias: userENuAlCU8alMeta?.alias || [],
    redirect: userENuAlCU8alMeta?.redirect || undefined,
    component: () => import("/tmp/build_96f1cc98/apps/web/src/pages/dashboard/user.vue").then(m => m.default || m)
  }
],
    name: dashboard2VOvHX9DjZMeta?.name ?? undefined,
    meta: dashboard2VOvHX9DjZMeta || {},
    alias: dashboard2VOvHX9DjZMeta?.alias || [],
    redirect: dashboard2VOvHX9DjZMeta?.redirect || undefined,
    component: () => import("/tmp/build_96f1cc98/apps/web/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexcSvZYyk7ygMeta?.name ?? "index",
    path: indexcSvZYyk7ygMeta?.path ?? "/",
    meta: indexcSvZYyk7ygMeta || {},
    alias: indexcSvZYyk7ygMeta?.alias || [],
    redirect: indexcSvZYyk7ygMeta?.redirect || undefined,
    component: () => import("/tmp/build_96f1cc98/apps/web/src/pages/index.vue").then(m => m.default || m)
  }
]